import { Link } from "gatsby";
import React from "react";
import BidddyLogo from "../../Images/BidddyLogo";
import {
  EmailIcon,
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
  TwitterIcon,
} from "../../Icons/SocialIcons";

const Footer = () => (
  <footer className="w-full bg-bidddyPink pt-16 md:pt-40 md:pb-24 pb-16">
    <div className="max-w-6xl w-full mx-auto px-5 flex flex-col justify-between gap-8">
      <div className="w-full flex md:flex-row flex-row flex-wrap">
        <div className="md:w-1/5 w-1/2 flex flex-col">
          <h5 className="text-white font-inter font-bold text-base mb-6 uppercase mt-6">
            Products
          </h5>
          <ul className="font-inter font-normal text-base text-white">
            <li>
              <Link
                className="font-inter font-medium md:text-base text-sm  hover:opacity-80"
                to="/features#create-or-schedule"
              >
                Auctions
              </Link>
            </li>
            <li>
              <Link
                className="font-inter font-medium md:text-base text-sm  hover:opacity-80"
                to="/features#event-page"
              >
                Event Auctions
              </Link>
            </li>
            <li>
              <Link
                className="font-inter font-medium md:text-base text-sm  hover:opacity-80"
                to="/features#bidddy-live"
              >
                Marketplace
              </Link>
            </li>
            <li>
              <Link
                className="font-inter font-medium md:text-base text-sm  hover:opacity-80"
                to="/features"
              >
                Features & Benefits
              </Link>
            </li>
            <li>
              <Link
                className="font-inter font-medium md:text-base text-sm  hover:opacity-80"
                to="/pricing"
              >
                Pricing
              </Link>
            </li>
          </ul>
        </div>
        <div className="md:w-1/5 w-1/2 flex flex-col">
          <h5 className="text-white font-inter font-bold text-base mb-6 uppercase mt-6">
            Learn More
          </h5>
          <ul className="font-inter font-normal text-base text-white">
            <li>
              <Link
                className="font-inter font-medium md:text-base text-sm  hover:opacity-80"
                to="/learn"
              >
                Blog
              </Link>
            </li>
            <li>
              <Link
                className="font-inter font-medium md:text-base text-sm  hover:opacity-80"
                to="/learn"
              >
                Tips & Tricks
              </Link>
            </li>
            <li>
              <Link
                className="font-inter font-medium md:text-base text-sm  hover:opacity-80"
                to="/contact-us"
              >
                Support
              </Link>
            </li>
            <li>
              <Link
                className="font-inter font-medium md:text-base text-sm  hover:opacity-80"
                to="/learn/how-it-works"
              >
                How it Works
              </Link>
            </li>
            <li>
              <Link
                className="font-inter font-medium md:text-base text-sm  hover:opacity-80"
                to="/charities"
              >
                Charities / Non-profits
              </Link>
            </li>
            <li>
              <Link
                className="font-inter font-medium md:text-base text-sm  hover:opacity-80"
                to="/dashboard/account/notification-settings/"
              >
                Notifications
              </Link>
            </li>
          </ul>
        </div>
        <div className="md:w-1/5 w-1/2 flex flex-col">
          <h5 className="text-white font-inter font-bold text-base mb-6 uppercase mt-6">
            Case Study
          </h5>
          <ul className="font-inter font-normal text-base text-white">
            <li>
              <Link
                className="font-inter font-medium md:text-base text-sm  hover:opacity-80"
                to="/case-studies#icka"
              >
                Island Kids Cancer
              </Link>
            </li>
            <li>
              <Link
                className="font-inter font-medium md:text-base text-sm  hover:opacity-80"
                to="/case-studies#toronto-thrive"
              >
                Toronto Thrive
              </Link>
            </li>
            <li>
              <Link
                className="font-inter font-medium md:text-base text-sm  hover:opacity-80"
                to="/case-studies#small-business"
              >
                Small Business
              </Link>
            </li>
          </ul>
        </div>
        <div className="md:w-1/5 w-1/2 flex flex-col">
          <h5 className="text-white font-inter font-bold text-base mb-6 uppercase mt-6">
            Contact
          </h5>
          <ul className="font-inter font-normal text-base text-white">
            <li>
              <Link
                className="font-inter font-medium md:text-base text-sm  hover:opacity-80"
                to="/contact-us"
              >
                Email
              </Link>
            </li>
            <li>
              <a
                className="font-inter font-medium md:text-base text-sm  hover:opacity-80"
                href="https://www.instagram.com/getbidddy"
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                Instagram
              </a>
            </li>
            <li>
              <a
                className="font-inter font-medium md:text-base text-sm  hover:opacity-80"
                href="https://www.facebook.com/getbidddy"
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                Facebook
              </a>
            </li>
          </ul>
        </div>
        <div className="md:w-1/5 w-1/2 flex flex-col">
          <h5 className="text-white font-inter font-bold text-base mb-6 uppercase mt-6">
            Resources
          </h5>
          <ul className="font-inter font-normal text-base text-white">
            <li>
              <Link
                className="font-inter font-medium md:text-base text-sm  hover:opacity-80"
                to="/privacy-policy"
              >
                Privacy Policy
              </Link>
            </li>
            <li>
              <Link
                className="font-inter font-medium md:text-base text-sm hover:opacity-80"
                to="/terms-of-service"
              >
                Terms of Service
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="flex flex-row justify-center items-center mx-auto gap-8">
        <a
          href="https://www.facebook.com/getbidddy"
          target="_blank"
          rel="noopener nofollow noreferrer"
        >
          <FacebookIcon className="text-white hover:opacity-80" />
        </a>
        <a
          href="https://www.linkedin.com/company/getbidddy"
          rel="noopener nofollow noreferrer"
          target="_blank"
        >
          <LinkedInIcon className="text-white hover:opacity-80" />
        </a>
        <a
          href="https://www.instagram.com/getbidddy"
          target="_blank"
          rel="noopener nofollow noreferrer"
        >
          <InstagramIcon className="text-white hover:opacity-80" />
        </a>
        <a
          href="https://twitter.com/getBidddy"
          target="_blank"
          rel="noopener nofollow noreferrer"
        >
          <TwitterIcon className="text-white hover:opacity-80" />
        </a>
        <Link to="/contact-us">
          <EmailIcon className="text-white hover:opacity-80" />
        </Link>
      </div>
      <div className="mx-auto mt-12">
        <BidddyLogo className="text-white w-32" />
      </div>
    </div>
  </footer>
);

export default Footer;
